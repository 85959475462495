.ra-lightbox {
  position: fixed;
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  overflow: visible;

  &__content {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    img {
      display: flex !important;
    }
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 10;
    outline: 1px solid var(--color-white);
    background-color: var(--color-white);
    border-radius: 50%;
    outline-offset: 2px;
  }
}
